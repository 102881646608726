const actionsAlert = {
    ALERT_SUCCESS: "ALERT_SUCCESS",
    ALERT_ERROR: "ALERT_ERROR",
    ALERT_HIDE: "ALERT_HIDE",

    /* success alert */
    alertSuccess: data => {
        return {
            type: actionsAlert.ALERT_SUCCESS,
            data: data
        }
    },

    /* error alert */
    alertError: data => {
        return {
            type: actionsAlert.ALERT_ERROR,
            data: data
        }
    },

    /* hide alert */
    alertHide: data => {
        return {
            type: actionsAlert.ALERT_HIDE,
            data: data
        }
    },
}

export default actionsAlert;