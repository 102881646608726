import { Col, Row } from 'antd';
import React from 'react';
import { Aside } from './overview/style';

const AuthLayout = WraperContent => {
  return () => {
    return (
      <Row className='authRow'>
        <Col xxl={12} xl={9} lg={12} md={10} xs={24}>
          <Aside>
            <div className="auth-side-content">
            </div>
          </Aside>
        </Col>
        <Col xxl={8} xl={14} lg={12} md={14} xs={24}>
          <WraperContent />
        </Col>
      </Row>
    );
  };
};

export default AuthLayout;
