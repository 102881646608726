import actionsEvents from './actions';

const { ALL_EVENTS, ADD_EVENT, EDIT_EVENT, DELETE_EVENT, UPLOAD_EVENT_IMAGE_ONE, UPLOAD_EVENT_IMAGE_TWO, UPLOAD_EVENT_LOADING_ONE, UPLOAD_EVENT_LOADING_TWO, UPLOAD_EVENT_ERROR_ONE, UPLOAD_EVENT_ERROR_TWO, DELETE_UPLOAD_DATA, GET_ONE_EVENT } = actionsEvents;

const initialState = {
    allEvents: [],
    eventAdded: false,
    eventEdited: false,
    eventDeleted: false,
    eventImageOneName: null,
    eventImageTwoName: null,
    eventImageOneLoading: false,
    eventImageTwoLoading: false,
    eventImageOneError: false,
    eventImageTwoError: false,
    oneEvent: null
}
const EventsReducer = (state = initialState, action) => {
    const { type, data } = action;
    switch (type) {
        case ALL_EVENTS:
            return { ...state, allEvents: data };
        case ADD_EVENT:
            return { ...state, eventAdded: data };
        case EDIT_EVENT:
            return { ...state, eventEdited: data };
        case DELETE_EVENT:
            return { ...state, eventDeleted: data };
        case UPLOAD_EVENT_IMAGE_ONE:
            return { ...state, eventImageOneName: data };
        case UPLOAD_EVENT_IMAGE_TWO:
            return { ...state, eventImageTwoName: data };
        case UPLOAD_EVENT_LOADING_ONE:
            return { ...state, eventImageOneLoading: data };
        case UPLOAD_EVENT_LOADING_TWO:
            return { ...state, eventImageTwoLoading: data };
        case UPLOAD_EVENT_ERROR_ONE:
            return { ...state, eventImageOneError: data };
        case UPLOAD_EVENT_ERROR_TWO:
            return { ...state, eventImageTwoError: data };
        case DELETE_UPLOAD_DATA:
            return { ...state, eventImageOneName: '', eventImageTwoName: '' };
        case GET_ONE_EVENT:
            return { ...state, oneEvent: data };
        default:
            return state;
    }
};
export default EventsReducer;
