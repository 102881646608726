import actions from './actions';
import { API_HOST, API } from '../../config/api'
import axios from 'axios';

const { loginSuccess, logoutSuccess, loginBegin, loginErr } = actions;

/* login */
const login = (values, history, stay) => {
  return async dispatch => {
    dispatch(loginBegin());
    const URL = API_HOST + API.auth.login
    axios.post(URL, values).then(
      (res) => {
        localStorage.setItem("token", res.data.token);
        if (stay === true) {
          localStorage.setItem('logedIn', true);
          localStorage.setItem('token', res.data.token);
        } else {
          localStorage.setItem('logedIn', true);
          localStorage.setItem('token', res.data.token);
        }
        history.push('/admin');
        return dispatch(loginSuccess(true));
      },
      (err) => {
        dispatch(loginErr(err.response.data))
        if (err.response.data.message)
          console.log(err)
      }
    )
  };
};

/* logout */
const logOut = () => {
  return async dispatch => {
    try {
      localStorage.removeItem('logedIn');
      localStorage.removeItem('token');
      dispatch(logoutSuccess(null));
    } catch (err) { }
  };
};

export { login, logOut };
