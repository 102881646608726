import actionsQuestion from './actions';

const { ALL_QUESTIONS, GET_ONE_QUESTION, EDIT_ONE_QUESTION, ADD_QUESTION, REMOVE_DATA, DELETE_QUESTION, GET_QR_CODE } = actionsQuestion;

const initialState = {
  allQuestions: [],
  oneQuestion: [],
  questionAdded: false,
  questionEdited: false,
  questionDeleted: false,
  qrCode: null,
}

const QuestionReducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case ALL_QUESTIONS:
      return { ...state, allQuestions: data };
    case GET_ONE_QUESTION:
      return { ...state, oneQuestion: data };
    case ADD_QUESTION:
      return { ...state, questionAdded: data };
    case EDIT_ONE_QUESTION:
      return { ...state, questionEdited: data };
    case REMOVE_DATA:
      return { ...state, oneQuestion: [] };
    case DELETE_QUESTION:
      return { ...state, questionDeleted: data };
    case GET_QR_CODE:
      return { ...state, qrCode: data };
    default:
      return state;
  }
};
export default QuestionReducer;
