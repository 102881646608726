import actionsUser from './actions';

const { ALL_USERS, GET_ONE_USER, TOP_USERS, GET_CSV_USERS, GET_ELITE_CSV_USERS } = actionsUser;

const initialState = {
  allUsers: [],
  topUsers: [],
  oneUser: [],
  csvUsers: null,
  eliteCsvUsers: null,
}

const UserReducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case ALL_USERS:
      return { ...state, allUsers: data };
    case TOP_USERS:
      return { ...state, topUsers: data };
    case GET_ONE_USER:
      return { ...state, oneUser: data };
    case GET_CSV_USERS:
      return { ...state, csvUsers: data };
    case GET_ELITE_CSV_USERS:
      return { ...state, eliteCsvUsers: data };
    default:
      return state;
  }
};
export default UserReducer;
