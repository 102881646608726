import { combineReducers } from 'redux';
import AdminReducer from './admins/reducers';
import AlertReducer from './alert/reducers';
import answersReducer from './answers/reducers';
import authReducer from './authentication/reducers';
import ChangeLayoutMode from './themeLayout/reducers';
import EventsReducer from './events/reducers';
import QuestionReducer from './questions/reducers';
import UserReducer from './users/reducers';

const rootReducers = combineReducers({
  admin: AdminReducer,
  alert: AlertReducer,
  answers: answersReducer,
  auth: authReducer,
  ChangeLayoutMode,
  events: EventsReducer,
  question: QuestionReducer,
  user: UserReducer,
});

export default rootReducers;
