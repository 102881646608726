const actions = {
    ALL_QUESTIONS: 'ALL_QUESTIONS',
    GET_ONE_QUESTION: 'GET_ONE_QUESTION',
    EDIT_ONE_QUESTION: 'EDIT_ONE_QUESTION',
    ADD_QUESTION: 'ADD_QUESTION',
    REMOVE_DATA: 'REMOVE_DATA',
    DELETE_QUESTION: 'DELETE_QUESTION',
    GET_QR_CODE: 'GET_QR_CODE',

    allQuestions: data => {
        return {
            type: actions.ALL_QUESTIONS,
            data,
        }
    },

    getOneQuestion: data => {
        return {
            type: actions.GET_ONE_QUESTION,
            data,
        }
    },

    editQuestion: data => {
        return {
            type: actions.EDIT_ONE_QUESTION,
            data,
        }
    },

    addQuestion: data => {
        return {
            type: actions.ADD_QUESTION,
            data,
        }
    },

    removeDataQuestion: data => {
        return {
            type: actions.REMOVE_DATA,
            data,
        }
    },

    deleteQuestion: data => {
        return {
            type: actions.DELETE_QUESTION,
            data,
        }
    },

    getQuestionQrCode: data => {
        return {
            type: actions.GET_QR_CODE,
            data,
        }
    },
}

export default actions;