import actionsAnswers from './actions';

const { GET_QUESTION_ANSWERS, DELETE_QUESTION_ANSWERS } = actionsAnswers;

const initialState = {
    answers: [],
}

const answersReducer = (state = initialState, action) => {
    const { type, data } = action;
    switch (type) {
        case GET_QUESTION_ANSWERS:
            return { ...state, answers: data };
        case DELETE_QUESTION_ANSWERS:
            return { ...state, answers: [] };
        default:
            return state;
    }
};
export default answersReducer;
