const API_HOST = "https://api-prod.villagefranco.prod-projet.com/";

const API = {
  auth: {
    login: 'auth/login',
  },
  user: {
    users: 'users/',
    deleteMany: 'users/delete',
    admins: 'users/admins', // get list of admins
    admin: 'users/admin', // post create admin 
    usersByEvent: '/users/participants/event/'
  },
  question: {
    questions: 'questions/',
    deleteMany: 'questions/delete'
  },
  answers: {
    questionAnswers: (id) => `/answers/question/${id}`
  },
  events: {
    events: 'events/',
    upload: 'file'
  }
};

export { API_HOST, API };
