import actionsAdmin from './actions';

const { ALL_ADMINS, ONE_ADMIN, DELETE_ADMINS, ADD_ADMIN } = actionsAdmin;

const initialState = {
  allAdmins: [],
  oneAdmin: [],
  deleteAdmin: [],
  addAdmin: null
}

const AdminReducer = (state = initialState, action) => {
  const { type, data } = action;
  switch (type) {
    case ALL_ADMINS:
      return { ...state, allAdmins: data };
    case ONE_ADMIN:
      return { ...state, oneAdmin: data };
    case DELETE_ADMINS:
      return { ...state, deleteAdmin: data };
    case ADD_ADMIN:
      return { ...state, addAdmin: data };
    default:
      return state;
  }
};
export default AdminReducer;
