const actions = {
    GET_QUESTION_ANSWERS: 'GET_QUESTION_ANSWERS',
    DELETE_QUESTION_ANSWERS: 'DELETE_QUESTION_ANSWERS',

    getQuestionAnswers: data => {
        return {
            type: actions.GET_QUESTION_ANSWERS,
            data,
        }
    },

    deleteQuestionAnswers: data => {
        return {
            type: actions.DELETE_QUESTION_ANSWERS,
            data,
        }
    },
}

export default actions;