import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import withAdminLayout from '../../layout/withAdminLayout';

const Dashboard = lazy(() => import('../../container/dashboard'));
const Clients = lazy(() => import('../../container/users/clients/index'));
const DetailsClients = lazy(() => import('../../container/users/clients/clientDetails/index'));
const Questions = lazy(() => import('../../container/questions/questions-list/index'));
const DetailQuestion = lazy(() => import('../../container/questions/question-detail/index'));
const Admins = lazy(() => import('../../container/users/admins/adminList/index'));
const DetailsAdmin = lazy(() => import('../../container/users/admins/adminDetails/index'));

const Admin = () => {
  const { path } = useRouteMatch();

  const fallback = () => {
    return (
      <div className="spin">
        <Spin />
      </div>
    )
  }

  return (
    <Switch>
      <Suspense fallback={fallback()}>
        <Route exact path={path} component={Dashboard} />
        <Route exact path={`${path}/participants`} component={Clients} />
        <Route exact path={`${path}/participants/:id`} component={DetailsClients} />
        <Route exact path={`${path}/question`} component={Questions} />
        <Route exact path={`${path}/questions/addQuestion`} component={DetailQuestion} />
        <Route exact path={`${path}/question/:id`} component={DetailQuestion} />
        <Route exact path={`${path}/admins`} component={Admins} />
        <Route exact path={`${path}/admin-details/:id`} component={DetailsAdmin} />
      </Suspense>
    </Switch>
  );
};

export default withAdminLayout(Admin);
