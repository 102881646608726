const actions = {
    ALL_USERS: 'ALL_USERS',
    TOP_USERS: 'TOP_USERS',
    GET_ONE_USER: 'GET_ONE_USER',
    GET_CSV_USERS: 'GET_CSV_USERS',
    GET_ELITE_CSV_USERS: 'GET_ELITE_CSV_USERS',

    allUsers: data => {
        return {
            type: actions.ALL_USERS,
            data,
        }
    },

    topUsers: data => {
        return {
            type: actions.TOP_USERS,
            data,
        }
    },

    getOneUser: data => {
        return {
            type: actions.GET_ONE_USER,
            data,
        }
    },

    getCsvUsers: data => {
        return {
            type: actions.GET_CSV_USERS,
            data,
        }
    },

    getEliteCsvUsers: data => {
        return {
            type: actions.GET_ELITE_CSV_USERS,
            data,
        }
    },
}

export default actions;