import thunk from "redux-thunk"
import { legacy_createStore as createStore, applyMiddleware, compose } from 'redux';

import rootReducer from './rootReducers';


const middleware = [thunk];
// @ts-ignore
const devtools = window.__REDUX_DEVTOOLS_EXTENSION__
  // @ts-ignore
  ? window.__REDUX_DEVTOOLS_EXTENSION__()
  : (f) => f;

const store = createStore(
  rootReducer,
  compose(applyMiddleware(...middleware), devtools)
);


export default store;
