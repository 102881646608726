const actions = {
    ALL_EVENTS: 'ALL_EVENTS',
    ADD_EVENT: 'ADD_EVENT',
    EDIT_EVENT: 'EDIT_EVENT',
    DELETE_EVENT: 'DELETE_EVENT',
    UPLOAD_EVENT_IMAGE_ONE: 'UPLOAD_EVENT_IMAGE_ONE',
    UPLOAD_EVENT_IMAGE_TWO: 'UPLOAD_EVENT_IMAGE_TWO',
    UPLOAD_EVENT_LOADING_ONE: 'UPLOAD_EVENT_LOADING_ONE',
    UPLOAD_EVENT_LOADING_TWO: 'UPLOAD_EVENT_LOADING_TWO',
    UPLOAD_EVENT_ERROR_ONE: 'UPLOAD_EVENT_ERROR_ONE',
    UPLOAD_EVENT_ERROR_TWO: 'UPLOAD_EVENT_ERROR_TWO',
    DELETE_UPLOAD_DATA: 'DELETE_UPLOAD_DATA',
    GET_ONE_EVENT: 'GET_ONE_EVENT',

    allEvents: data => {
        return {
            type: actions.ALL_EVENTS,
            data,
        }
    },

    addEvent: data => {
        return {
            type: actions.ADD_EVENT,
            data,
        }
    },

    editEvent: data => {
        return {
            type: actions.EDIT_EVENT,
            data,
        }
    },

    deleteEvent: data => {
        return {
            type: actions.DELETE_EVENT,
            data,
        }
    },

    uploadEventImageOne: data => {
        return {
            type: actions.UPLOAD_EVENT_IMAGE_ONE,
            data,
        }
    },

    uploadEventImageTwo: data => {
        return {
            type: actions.UPLOAD_EVENT_IMAGE_TWO,
            data,
        }
    },

    uploadEventImageLoadingOne: data => {
        return {
            type: actions.UPLOAD_EVENT_LOADING_ONE,
            data,
        }
    },

    uploadEventImageLoadingTwo: data => {
        return {
            type: actions.UPLOAD_EVENT_LOADING_TWO,
            data,
        }
    },

    uploadEventImageErrorOne: data => {
        return {
            type: actions.UPLOAD_EVENT_ERROR_ONE,
            data,
        }
    },

    uploadEventImageErrorTwo: data => {
        return {
            type: actions.UPLOAD_EVENT_ERROR_TWO,
            data,
        }
    },

    deleteUploadData: data => {
        return {
            type: actions.DELETE_UPLOAD_DATA,
            data,
        }
    },

    getOneEvent: data => {
        return {
            type: actions.GET_ONE_EVENT,
            data,
        }
    },
}

export default actions;