import React, { useState } from 'react';
import { Menu } from 'antd';
import { NavLink, useRouteMatch } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import { Div } from './style';
import QRCode from 'qrcode'
import FileSaver from 'file-saver';

const MenuItems = ({ toggleCollapsed }) => {
  const { path } = useRouteMatch();
  var pathName = window.location.pathname.split('/');
  const [openKeys, setOpenKeys] = useState(pathName.length > 2 ? pathName.slice(2, pathName.length) : []);
  /* click on sub menu with item */

  const onOpenChange = keys => {
    setOpenKeys([keys.pop()]);
  };
  /* click on sub menu without items */
  const onClick = item => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const generateQR = text => {
    try {
      QRCode.toDataURL(text, { width: 500, height: 500 }).then(res => {
        FileSaver.saveAs(res, "qrCode.png")
      })
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={'inline'}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
      defaultSelectedKeys={[window.location.pathname.split('/').pop() === "admin" ? 'participants' : window.location.pathname.split('/').pop()]}
    >
      <Div className={window.innerWidth > 991 ? 'striking-logo top-menu' : 'striking-logo'} to="/">
        <img src={require(`../static/img/auth/authPic.png`)} alt="" />
      </Div>
      <Menu.Item key="events" className={pathName[2] === '/' ? 'active-route mb-20' : 'white mb-30'}
        icon={
          <NavLink className="menuItem-iocn" to={`${path}`}>
            <FeatherIcon size={18} className="menuIcon" icon="arrow-left-circle" />
          </NavLink>
        }
      >
        <NavLink to={`${path}`}>
          Événements
        </NavLink>
      </Menu.Item>
      <Menu.Item key="participants" className={pathName[2] === 'participants' ? 'active-route' : 'white'}
        icon={
          <NavLink className="menuItem-iocn" to={`${path}/participants`}>
            <FeatherIcon size={18} className="menuIcon" icon="user" />
          </NavLink>
        }
      >
        <NavLink to={`${path}/participants`}>
          Participants
        </NavLink>
      </Menu.Item>
      <Menu.Item key="question" className={pathName[2] === 'question' ? 'active-route' : 'white'}
        icon={
          <NavLink className="menuItem-iocn" to={`${path}/question`}>
            <FeatherIcon size={18} className="menuIcon" icon="help-circle" />
          </NavLink>
        }
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/question`}>
          Questions
        </NavLink>
      </Menu.Item>
      <Menu.Item key="admins" className={pathName[2] === 'admins' ? 'active-route' : 'white'}
        icon={
          <NavLink className="menuItem-iocn" to={`${path}/admins`}>
            <FeatherIcon size={18} className="menuIcon" icon="lock" />
          </NavLink>
        }
      >
        <NavLink onClick={toggleCollapsed} to={`${path}/admins`}>
          Admins
        </NavLink>
      </Menu.Item>
      <Menu.Item key="QR" className='qr-item white' onClick={() => generateQR("https://ifefo.app/")}
      >
        <img className='qr-code' heigth={100} width={100} src={require('../static/img/qrCodePng.png')} alt="Le code QR de l'application"></img>
      </Menu.Item>
    </Menu>

  );
};

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
