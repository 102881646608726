const actions = {
    ALL_ADMINS: 'ALL_ADMINS',
    ONE_ADMIN: 'ONE_ADMIN',
    DELETE_ADMINS: 'DELETE_ADMINS',
    ADD_ADMIN: 'ADD_ADMIN',

    allAdmins: data => {
        return {
            type: actions.ALL_ADMINS,
            data,
        }
    },

    oneAdmin: data => {
        return {
            type: actions.ONE_ADMIN,
            data,
        }
    },

    deleteAdmin: data => {
        return {
            type: actions.DELETE_ADMINS,
            data,
        }
    },

    addAdmin: data => {
        return {
            type: actions.ADD_ADMIN,
            data,
        }
    },
}

export default actions;